
import * as firebase from 'firebase/app';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY  ,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId:process.env.REACT_APP_MEASUREMENTID
  };

class Firebase {
    constructor() {
        firebase.initializeApp(firebaseConfig);
    }
  }

export default Firebase;