import {
    makeStyles,
  } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    container :{
        display:"flex",
        flexDirection:"row",
    },
    textBlock: {
        maxWidth:'50px'
    }

}));