import {
    makeStyles,
  } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    button: {
      color:'black',
      margin:'5px'
    }

}));